import styles from "./SearchHeader.module.css";
import DatePickerBox from "./DatePickerBox";
import RoomCard from "./RoomCard";
import { useContext, useEffect, useRef, useState } from "react";
import FilterBox from "./FilterBox";
import { getSearchMeta, getWebMain } from "@modules/api/Search";
import { useRouter } from "next/router";
import { Contains } from "@constants/contains";
import { getRoomQuery, setSearchDateCookie, setSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { useTranslation } from "next-i18next";
import { disableBodyScrollLock, enableBodyScrollLock } from "@modules/utils/BodyScrollLock";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
import IcSearchClear from "@public/images/common/ic_search_clear.svg";
import { useAtom } from "jotai";
import { dateBoxOpenAtom, mainPageVer2Atom, webMainAtom } from "@store/global";
import { useDebounce } from "@modules/hooks/useDebounce";
import { LANDING_TYPE, SEARCH_INPUT_DELAY, SEARCH_TYPE_ALLOW_MAP, SEARCH_TYPE_TINVEN, TYPE } from "@constants/common";
import SearchBtnIcon from "public/images/common/ic_search_ou_b_wh.svg";
import SearchBtnOldIcon from "/public/images/common/bg_btn_sch_h24.svg";
import dayjs from "dayjs";
import { fPixelTrack } from "@modules/lib/fpixel/track";
import { FACEBOOK_PIXEL_TRACKING_TYPE } from "@constants/scripts";
import { useDomain } from "@modules/hooks/useDomain";
import { MetaSourceContext } from "@components/meta/MetaSourceProvider";
import { setSearchHistory } from "@modules/utils/searchHistory";
export default function SearchHeader({
  className,
  searchData,
  setSearchData,
  isSearchClick,
  isMainDim,
  isTopZero,
  dimClickHandler
}) {
  // 다국어
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  const {
    locale
  } = useRouter();

  // dim
  const [viewState, setViewState] = useState({
    dim: false
  });
  const [webMain, setWebMain] = useAtom(webMainAtom);
  const searchSearchBoxRef = useRef();
  const roomsBoxRef = useRef();
  const [isFocusState, setIsFocusState] = useState({
    text: false,
    date: false,
    room: false
  });
  const [searchBoxTextFlag, setSearchBoxTextFlag] = useState(true);
  const [dateBoxOpen, setDateBoxOpen] = useAtom(dateBoxOpenAtom);
  const [tempSearchData, setTempSearchData] = useState(searchData);
  const [isLoaded, setIsLoaded] = useState(false);
  // 검색어
  const [searchMetaData, setSearchMetaData] = useState(null);
  const searchTextRef = useRef();
  // 인원
  const [selectRoomCnt, setSelectRoomCnt] = useState(1);
  const [selectPeopleCnt, setSelectPeopleCnt] = useState(2);
  const txtInput = useDebounce(searchData.query, SEARCH_INPUT_DELAY);
  const timeStamp = useRef("");
  const [searchDataChangeFlag, setSearchDataChangeFlag] = useState(false);
  const isTTBB = useDomain();
  const contextMetaSource = useContext(MetaSourceContext);
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기
  // const [filterBoxChanged, setFilterBoxChanged] = useState(false);

  const [recommendKeywordClick, setRecommendKeywordClick] = useState(false);
  useEffect(() => {
    if (!router.isReady) return;
    setIsLoaded(true);
  }, [router.isReady]);
  useEffect(() => {
    if (recommendKeywordClick) {
      setRecommendKeywordClick(false);
      setSearchDataChangeFlag(false);
      setSearchBoxTextFlag(true);
      setIsFocusState({
        text: false,
        date: false,
        room: false
      });
    }
  }, [recommendKeywordClick]);
  const getMetaData = async () => {
    try {
      const dataTimestamp = +new Date();
      let data;
      if (txtInput) {
        data = await getSearchMeta(locale, {
          query: txtInput,
          offset: 0,
          limit: !mainPageVer2 ? 10 : 15,
          indices: [{
            name: "recommend_keyword"
          }, {
            name: "meta_region"
          }, {
            name: "meta_hotel"
          }]
        });
      }
      if (dataTimestamp > timeStamp.current) {
        if (txtInput) {
          setSearchMetaData(data.body);
        } else {
          setSearchMetaData(null);
        }
        timeStamp.current = dataTimestamp;
      }
    } catch (e) {
      console.log("metaDataAPIError :", e);
    }
  };
  useEffect(() => {
    getMetaData();
  }, [locale, txtInput]);

  // 데이터 호출
  useEffect(() => {
    getWebMain(locale, contextMetaSource.meta.name).then(data => setWebMain(data.body));
    roomSelectCompleteHandle(searchData.roomsInfo);
  }, [locale, contextMetaSource]);
  useEffect(() => {
    if (!searchBoxTextFlag && !recommendKeywordClick) {
      setSearchDataChangeFlag(true);
      setSearchData({
        ...searchData,
        query: "",
        searchText: ""
      });
    }
  }, [searchBoxTextFlag]);
  useEffect(() => {
    if (dateBoxOpen) {
      setIsFocusState({
        ...isFocusState,
        date: true
      });
      setViewState({
        ...viewState,
        dim: true
      });
      enableBodyScrollLock();
    }
  }, [dateBoxOpen]);
  useEffect(() => {
    let timeout;
    if (isFocusState.text || isFocusState.date || isFocusState.room) {
      setViewState({
        ...viewState,
        dim: true
      });
      enableBodyScrollLock();
    } else {
      timeout = window.setTimeout(() => {
        setViewState({
          ...viewState,
          dim: false
        });
        disableBodyScrollLock();
        if (dimClickHandler) {
          dimClickHandler();
        }
      }, 120);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFocusState]);
  useEffect(() => {
    if (isSearchClick?.clickItem === "text") {
      setIsFocusState({
        ...isFocusState,
        text: true
      });
    } else if (isSearchClick?.clickItem === "date") {
      setIsFocusState({
        ...isFocusState,
        date: true
      });
    } else if (isSearchClick?.clickItem === "room") {
      setIsFocusState({
        ...isFocusState,
        room: true
      });
    } else if (isSearchClick?.clickItem === "button") {
      searchBtnHandler();
    }
  }, [isSearchClick]);
  useEffect(() => {
    if (searchData?.showRoomsLayer) {
      setIsFocusState({
        ...isFocusState,
        room: true
      });
      setSearchData({
        ...searchData,
        showRoomsLayer: false
      });
    }
  }, [searchData?.showRoomsLayer]);
  useEffect(() => {
    if (typeof window !== undefined) {
      const resetTxtInput = e => {
        if (isFocusState.text && searchData.query && e.keyCode === 27) {
          setSearchBoxTextFlag(false);
          searchTextRef.current?.focus();
        }
      };
      window.addEventListener("keydown", resetTxtInput);
      return () => window.removeEventListener("keydown", resetTxtInput);
    }
  }, [isFocusState.text, searchData.query]);

  // useEffect(() => {
  //   if (filterBoxChanged) {
  //     searchBtnHandler();
  //     setFilterBoxChanged(false); // 플래그를 재설정
  //   }
  // }, [filterBoxChanged]);

  // 검색 버튼 핸들러 연결
  const searchBtnHandler = () => {
    setSearchDataChangeFlag(false);
    if (!searchData.query || !searchData.searchId) {
      setIsFocusState({
        ...isFocusState,
        text: true
      });
      return;
    }
    disableBodyScrollLock();
    setIsFocusState({
      text: false,
      date: false,
      room: false
    });
    setSearchDateCookie(tempSearchData.startDate, tempSearchData.endDate);
    setSearchRoomsCookie(tempSearchData.roomsInfo);
    setSearchHistory(searchData);
    setSearchData({
      ...searchData,
      startDate: tempSearchData.startDate,
      endDate: tempSearchData.endDate,
      roomsInfo: tempSearchData.roomsInfo
    });
    const query = {
      "check-in": dayjs(tempSearchData.startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(tempSearchData.endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(tempSearchData.roomsInfo),
      query: searchData.query,
      searchId: searchData.searchId,
      searchType: searchData.searchType,
      searchText: searchData.searchText
    };
    const {
      type,
      tinven_type,
      landingType
    } = router.query;
    if (!searchData.searchType) {
      if (type) {
        query.type = type === TYPE.HOTEL ? "keyword" : type;
      }
      if (type === SEARCH_TYPE_TINVEN) {
        query.tinven_type = tinven_type;
      }
      if (landingType) {
        query.landingType = landingType;
      }
    }
    if (!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
      fPixelTrack(FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH, query);
    }

    // if (isSrookpay) {
    //   if (router.pathname.includes("hotels") && searchData.searchId === isSrookpay.id) {
    //     Object.entries(isSrookpay).map(([key, value]) => {
    //       query[key] = value;
    //     });
    //   }
    //   if (
    //     (!router.pathname.includes("hotels") &&
    //       !router.pathname.includes("/checkout/") &&
    //       !router.pathname.includes("/signin/")) ||
    //     searchData.searchId !== isSrookpay.id
    //   )
    //     setIsSrookpay(null);
    // }
    //
    // // 지니티비(GenieTV 관련 코드)
    // if (getSessionStorage("genie")) {
    //   if (searchData.searchId == sessionStorage.getItem("genie")) {
    //     query["utm_source"] = "GENIE";
    //   }
    // }

    if (searchData.searchType === Contains.HOTEL) {
      router.replace({
        pathname: "/hotels/[id]",
        query: {
          id: searchData.searchId,
          ...query
        }
      });
    } else {
      setSearchData({
        ...searchData,
        startDate: tempSearchData.startDate,
        endDate: tempSearchData.endDate,
        roomsInfo: tempSearchData.roomsInfo,
        query: searchData.query,
        searchId: searchData.searchId,
        searchType: searchData.searchType,
        searchBtnClick: true
      });
      router.replace({
        pathname: "/search",
        query: query
      });
    }
  };
  const searchTextHandle = e => {
    setSearchBoxTextFlag(true);
    setSearchData({
      ...searchData,
      query: e.target.value,
      searchText: e.target.value
    });
  };

  // 숙박일
  const handleCalendarClose = () => {
    setSearchDataChangeFlag(true);
    setIsFocusState({
      ...isFocusState,
      date: false
    });
    if (router?.pathname === "/" && mainPageVer2) {
      setSearchData(tempSearchData);
    }
  };
  const roomSelectCompleteHandle = (roomsInfo, isClose) => {
    setSelectRoomCnt(roomsInfo?.length ?? 0);
    let peopleCnt = 0;
    roomsInfo?.map(info => {
      peopleCnt = peopleCnt + info.people.adultCnt + info.people.childs.length;
    });
    setSelectPeopleCnt(peopleCnt);
    if (isClose === true) {
      setSearchDataChangeFlag(true);
      setTempSearchData({
        ...tempSearchData,
        roomsInfo: roomsInfo
      });
      setIsFocusState({
        text: false,
        date: false,
        room: false
      });
      if (router?.pathname === "/" && mainPageVer2) {
        setSearchData({
          ...tempSearchData,
          roomsInfo: roomsInfo
        });
      }
    }
  };
  const filterBoxChangeCallback = (item, date) => {
    // date가 있는 경우: 최근 검색으로 진입 했을때
    setSearchDataChangeFlag(true);
    setIsFocusState({
      ...isFocusState,
      text: false
    });
    setSearchBoxTextFlag(false);
    setRecommendKeywordClick(true);
    setSearchDateCookie(searchData.startDate, searchData.endDate);
    setSearchRoomsCookie(searchData.roomsInfo);
    const newSearchData = {
      ...searchData,
      startDate: item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_in).toDate() : dayjs(date ? date.searchData.startDate : searchData.startDate).toDate(),
      endDate: item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_out).toDate() : dayjs(date ? date.searchData.endDate : searchData.endDate).toDate(),
      roomsInfo: date ? date.searchData.roomsInfo : searchData.roomsInfo,
      query: date && item.query ? item.query : item.name,
      searchId: date && item.searchId ? item.searchId : item.landing_type === LANDING_TYPE.region_SEARCH ? item.region_id : item.id,
      searchType: date && item.searchType ? item.searchType : item.type === TYPE.HOTEL ? "keyword" : item.type,
      searchText: date && item.searchText ? item.searchText : item.name,
      searchBtnClick: true,
      isMapView: !SEARCH_TYPE_ALLOW_MAP.includes(item.searchType) ? false : searchData.isMapView
    };
    setSearchData(newSearchData);
    setTempSearchData(newSearchData);
    if (date) {
      roomSelectCompleteHandle(newSearchData.roomsInfo);
    }
    let query = {
      "check-in": item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_in).format("YYYY.MM.DD") : dayjs(searchData.startDate).format("YYYY.MM.DD"),
      "check-out": item.type === SEARCH_TYPE_TINVEN ? dayjs(item.check_out).format("YYYY.MM.DD") : dayjs(searchData.endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(searchData.roomsInfo),
      query: date && item.query ? item.query : item.name,
      searchId: date && item.searchId ? item.searchId : item.landing_type === LANDING_TYPE.region_SEARCH ? item.region_id : item.id,
      searchType: date && item.searchType ? item.searchType : item.type === TYPE.HOTEL ? "keyword" : item.type,
      searchText: date && item.searchText ? item.searchText : item.name,
      landingType: item.landing_type || item.searchType,
      type: (item.type === TYPE.HOTEL ? "keyword" : item.type) || item.searchType
    };
    if (date && item.type !== SEARCH_TYPE_TINVEN) {
      query = {
        ...query,
        ...date.searchQuery
      };
    }
    if (query.type === SEARCH_TYPE_TINVEN) {
      query.tinven_type = item.tinven_type;
    }
    if (!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
      fPixelTrack(FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH, query);
    }
    if (query.searchType === Contains.HOTEL) {
      router.push({
        pathname: `/hotels/${query.searchId}`,
        query: query
      });
    } else if (router.pathname === "/search") {
      router.replace({
        pathname: "/search",
        query: query
      });
    } else {
      router.push({
        pathname: "/search",
        query: query
      });
    }
  };
  return <>
      <div className={`fixed bottom-0 left-0 z-[5] h-full w-full tablet:hidden tablet:h-[calc(100%-129px)]
                    ${viewState.dim ? "block" : "hidden"}
                    ${isMainDim ? "" : "bg-DimBlack-7"}`} onClick={() => {
      if (dateBoxOpen) {
        setDateBoxOpen(false);
        handleCalendarClose();
      }
    }} />
      <div className={`reservDiv 
                    ${styles.reservDiv}
                    sticky z-[6] border-t border-solid border-y-Gray-50 bg-white tablet:top-[56px] tablet:hidden
                    ${isTopZero ? "top-0" : mainPageVer2 ? "top-[64px]" : "top-[136px]"}
                    ${!viewState.dim && "border-b"} 
                    ${className ?? ""}`}>
        <section className="search relative z-[2] mx-auto my-0 flex h-[72px] max-w-[1180px] flex-row items-center bg-white">
          <Box boxRef={searchSearchBoxRef} className={`box pl-[34px] ${isFocusState.text && "relative"}`} isFocus={isFocusState.text}>
            <input type="text" className="relative z-[2] mt-[1px] inline-block h-[calc(100%_-_2px)] w-[calc(100%_-_34px)] cursor-pointer overflow-ellipsis border-none pb-[9px]
              pl-0 pr-0 pt-[38px] text-[16px] font-medium leading-normal tracking-[-0.002em] text-Gray-800 caret-Primary500 placeholder:text-[16px] placeholder:font-medium placeholder:tracking-[-0.002em] placeholder:text-Gray-800" placeholder={t("searchbar.search.placeholder")} ref={searchTextRef} value={searchData.searchText || ""} maxLength={50} onChange={searchTextHandle} onFocus={event => {
            event.preventDefault();
            setIsFocusState({
              text: true,
              date: false,
              room: false
            });
            searchTextRef.current?.focus({
              preventScroll: true
            });
          }} />
            {isFocusState.text && searchData.query && <IcSearchClear width="18px" height="18px" className="absolute right-[30px] top-[41px] z-[10] cursor-pointer" onClick={() => {
            setSearchDataChangeFlag(true);
            setSearchBoxTextFlag(false);
            searchTextRef.current?.focus();
          }} />}
            <label className={`C_12_100_Medium absolute ${getValueByDomain("text-Gray-300", "text-Gray-500")} left-0 top-[16px] z-[2] pl-[34px] opacity-100`}>
              {t("searchbar.search.label")}
            </label>
            {isFocusState.text && <FilterBox searchSearchBoxRef={searchSearchBoxRef} searchTextRef={searchTextRef} searchData={searchData} setSearchData={newData => {
            setSearchData(newData);
            // if (mainPageVer2) {
            //   setFilterBoxChanged(true); // setSearchData가 호출될 때마다 플래그를 설정
            // }
          }} searchBoxTextFlag={searchBoxTextFlag} searchBoxCloseHandler={() => {
            setSearchDataChangeFlag(true);
            setIsFocusState({
              ...isFocusState,
              text: false
            });
            setSearchBoxTextFlag(true);
          }} searchKeywords={mainPageVer2 ? webMain.keywords : webMain.search_keywords} searchMetaData={searchData.query && searchMetaData} filterBoxChangeCallback={filterBoxChangeCallback} />}
          </Box>
          <Box className="box flex-[1_1_326px] cursor-default" isFocus={isFocusState.date}>
            {isLoaded && <DatePickerBox open={isFocusState.date} className={"inline-block h-auto w-full flex-grow border-none pb-[13px] pl-[32px] pr-0 pt-[36px] text-[16px] font-medium leading-normal tracking-[-0.002em] text-Gray-800"} dateFormat={t("search.datepicker.format")}
          // searchData={searchData}
          searchData={tempSearchData}
          // setSearchData={setSearchData}
          setSearchData={setTempSearchData} openHandler={() => {
            // console.log("datepicker focus");
            setIsFocusState({
              text: false,
              date: !isFocusState.date,
              room: false
            });
          }} closeHandler={handleCalendarClose} />}
            <label className={`C_12_100_Medium absolute ${getValueByDomain("text-Gray-300", "text-Gray-500")} left-[32px] top-[16px] opacity-100`}>
              {t("searchbar.days.label")}
            </label>
          </Box>
          <Box boxRef={roomsBoxRef} className="box flex-[1_1_305px] after:hidden" isFocus={isFocusState.room}>
            <p className="absolute z-10 flex w-full cursor-pointer items-center pb-[13px] pl-[32px] pt-[36px] text-center text-[16px] font-medium tracking-[-0.002em] text-Gray-800" onClick={() => {
            // console.log("roombox focus");
            setIsFocusState({
              text: false,
              date: false,
              room: !isFocusState.room
            });
          }}>
              {t("searchbar.people.txt", {
              n: {
                a: selectRoomCnt,
                b: selectPeopleCnt
              }
            })}
            </p>
            <label className={`C_12_100_Medium absolute ${getValueByDomain("text-Gray-300", "text-Gray-500")} left-[32px] top-[16px] opacity-100`}>
              {t("searchbar.people.label")}
            </label>
            {isFocusState.room && <RoomCard roomsBoxRef={roomsBoxRef} isRoomBoxFocus={isFocusState.room}
          // defaultRoomInfo={searchData.roomsInfo}
          defaultRoomInfo={tempSearchData.roomsInfo} roomBoxCloseHandler={() => {
            setIsFocusState({
              ...isFocusState,
              room: false
            });
          }} roomSelectCompleteHandle={roomSelectCompleteHandle} />}
          </Box>
          {/*<SearchBtn searchBtnHandler={searchBtnHandler}>검색</SearchBtn>*/}
          <div className="flex h-full w-[120px] items-center">
            <button id="btnSearch" className={`
                B_16_100_Bold flex h-[56px] w-[120px] flex-row items-center justify-center rounded-[50px] ${searchDataChangeFlag && searchData.query ? "bg-Gray-800 text-white" : ""}
              `} onClick={() => {
            searchBtnHandler();
          }}>
              {searchDataChangeFlag && searchData.query ? <>
                  <SearchBtnIcon w={16} h={16} />
                  <span className="ml-1">{t("searchbar.btn.search")}</span>
                </> : <SearchBtnOldIcon id="btnSearch" />}
            </button>
          </div>
        </section>
      </div>
    </>;
}
const Box = ({
  className,
  boxRef,
  isFocus,
  children
}) => {
  const focus = isFocus ? 'before:content-[""] before:absolute before:left-0 before:top-0 before:w-full before:h-[72px] before:border-solid before:border-Gray-900 before:border-[1px] before:rounded-[100px] before:z-[2] tablet:before:hidden' : "";
  return <div ref={boxRef} id={className} className={`relative h-[72px] flex-[1_1_549px] after:absolute after:right-[-1px] after:top-[16px] after:h-[40px] after:w-px after:bg-Gray-50 after:content-[''] ${className} ${focus}`}>
      {children}
    </div>;
};